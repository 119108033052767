import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "./Card";
import { useDispatch } from "react-redux";
import {
    setActivePage,
    setAddWorkspaceShowModal,
    setHomeRedirection,
} from "../../../../../../actions";
import {
    ArrowForward,
    ArrowForwardIosSharp,
    ArrowForwardIosTwoTone,
    ArrowForwardSharp,
} from "@mui/icons-material";
import { setAddWorkspaceModalAction } from "../../../../../../services/services/app";

export default function GettingStarted({ data, dismissGettingStarted }) {
    const dispatch = useDispatch();
    const [completed, setCompleted] = React.useState([]);
    React.useEffect(() => {
        if (data) {
            const tempCompleted = [];
            tempCompleted.push(data.isWorkspaceCreated);
            tempCompleted.push(data.isDirectoryCreated);
            tempCompleted.push(data.isRippleRun);
            tempCompleted.push(data.isFlowCreated);
            setCompleted(tempCompleted);
        }
    }, []);
    const getCreateWorkspace = () => {
        const actionButton1ClickHandler = () => {
            dispatch(setAddWorkspaceModalAction(true));
            // setTimeout(() => {
            //     document.getElementById("addWorkspace").click();
            // }, 1000);
        };
        const actionButton2ClickHandler = () => {
            // alert("Learn More");
            let link =
                "https://boardwalktech.atlassian.net/wiki/spaces/UW/pages/83427355/UC+-+How+to+Create+a+Workspace";
            window.open(link, "_blank");
        };
        return (
            <Card
                title={"Create a Workspace"}
                description={
                    "Centralize & manage all your information & data within spaces, documents & folders."
                }
                actionButton1={"Create"}
                actionButton2={"Learn More"}
                handleActionButton1Click={actionButton1ClickHandler}
                handleActionButton2Click={actionButton2ClickHandler}
            />
        );
    };
    const getCreateDirectory = () => {
        const actionButton1ClickHandler = () => {
            dispatch(setActivePage("Directory"));
            dispatch(setHomeRedirection({ flag: true, type: "" }));
        };
        const actionButton2ClickHandler = () => {
            // alert("Learn More");
            let link =
                "https://boardwalktech.atlassian.net/wiki/spaces/UW/pages/111738894/Directory+-+UC";
            window.open(link, "_blank");
        };
        return (
            <Card
                title={"Create a Directory"}
                description={
                    "Add team members to Unity Central so you can collaborate in chat, flow and workspaces."
                }
                actionButton1={"Create Directory"}
                actionButton2={"Learn More"}
                handleActionButton1Click={actionButton1ClickHandler}
                handleActionButton2Click={actionButton2ClickHandler}
            />
        );
    };
    const getRunRipple = () => {
        const actionButton1ClickHandler = () => {
            dispatch(setActivePage("Ripple"));
        };
        const actionButton2ClickHandler = () => {
            // alert("Learn More");
            let link =
                "https://boardwalktech.atlassian.net/wiki/spaces/UW/pages/83296353/UC+-+How+to+Use+Ripple";
            window.open(link, "_blank");
        };
        return (
            <Card
                title={"Run a Ripple"}
                description={
                    "Use the Ripple search engine to query your information across all Workspaces, Spaces, Folders and Files."
                }
                actionButton1={"Run Ripple"}
                actionButton2={"Learn More"}
                handleActionButton1Click={actionButton1ClickHandler}
                handleActionButton2Click={actionButton2ClickHandler}
            />
        );
    };
    const getCreateFlow = () => {
        const actionButton1ClickHandler = () => {
            dispatch(setActivePage("DocuChain"));
            dispatch(setHomeRedirection({ flag: true, type: "" }));
        };
        const actionButton2ClickHandler = () => {
            // alert("Learn More");
            let link =
                "https://boardwalktech.atlassian.net/wiki/spaces/UW/pages/83460130/UC+-+How+to+Use+Flow";
            window.open(link, "_blank");
        };
        return (
            <Card
                title={"Create a Flow"}
                description={
                    "Track analyze and gain visiblity into documents as they move through process."
                }
                actionButton1={"Create Flow"}
                actionButton2={"Learn More"}
                handleActionButton1Click={actionButton1ClickHandler}
                handleActionButton2Click={actionButton2ClickHandler}
            />
        );
    };
    const steps = [
        getCreateWorkspace(),
        getCreateDirectory(),
        getRunRipple(),
        getCreateFlow(),
    ];
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    width: "1,270px",
                    height: "50px",
                    padding: "10px",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        width: "160px",
                        height: "24px",
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontWeight: "600",
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "#1F2328",
                    }}
                >
                    Getting Started
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "90px",
                        height: "30px",
                        padding: "4px, 10px, 4px, 8px",
                        borderRadius: "4px",
                        gap: "8px",
                        border: "black",
                    }}
                    onClick={dismissGettingStarted}
                >
                    <div>
                        <img src="./images/stroke.svg" alt="" />
                    </div>
                    <div className="dismiss_value">Dismiss</div>
                </div>
            </div>
            <Box sx={{ width: "100%" }}>
                <Stepper
                    activeStep={0}
                    alternativeLabel
                    sx={{
                        height: "190px",
                        gap: "22px",
                        padding: "10px",
                    }}
                    nonLinear
                >
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </div>
    );
}
