import React, { useEffect, useState } from "react";
import "./home.css";
import GettingStarted from "./getting-started";
import NumberInfo from "./getting-started/NumberInfo";
import RecentActivity from "././recent-activities/recentActivity";
import AlertViewer from "../../AlertViewer";
import AlertManager from "../../../../../bae-client/AlertManager";
import {
    setAlertData,
    setAlertManager,
    updateArchiveInfoAlert,
    setAlertsWidget,
    setActivePage,
    setAddWorkspaceShowModal,
} from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import ListView from "../../../../stylized/ListView";
import EditAlertModalView from "../Home/Alert/EditAlertModalView";

import DataComponent from "./resource";
import NewChat from "./chat";
import http from "../../../../../bae-client/HTTP";
import { EmptyHomeScreen } from "../../../../stylized/EmptyHomeScreen";
import Alerts from "./alerts";
import WelcomeShow from "../WorkspaceContainer/welcomePage";
import NestedModal from "../../../../stylized/nested-modal/NestedModal";

const Home = () => {
    const dispatch = useDispatch();
    const [showTreeView, setShowTreeView] = useState(false);
    const selectedAlertIndex = useSelector((state) => state.selectedAlertIndex);
    const alertmanagerGlobal = useSelector((state) => state.alertManager);
    const [allAlerts, setAllAlerts] = useState([]);
    const [alertDataList, setAlertDataList] = useState([]);
    const [homeAlerts, setHomeAlerts] = useState([]);
    const [selectedAlertId, setSelectedAlertId] = useState([]);
    const [alertInfoModal, setAlertInfoModal] = useState(false);
    const [selectedAlertData, setSelectedAlertData] = useState({});
    const [alertTypeData, setAlertTypeData] = useState("");
    const [counts, setCounts] = useState({
        workspace: 7,
        directoryMember: 3,
        document: 33,
        flowInstance: 19,
    });
    const [gettingStarted, setGettingStarted] = useState(null);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const getAlertsForHome = () => {
        let homeAlertsArray = [];
        if (alertDataList !== undefined && alertDataList !== null) {
            alertDataList.forEach((alertItem) => {
                if (alertItem.archive === 0) {
                    homeAlertsArray.push(alertItem);
                }
            });
        }
        homeAlertsArray.sort((a, b) => {
            return (
                new Date(b.updatedOn).getTime() -
                new Date(a.updatedOn).getTime()
            );
        });
        setHomeAlerts(homeAlertsArray);
    };
    useEffect(() => {
        http.sendRestRequest("home/elementCounts")
            .then((response) => {
                setCounts(response);
            })
            .catch((err) => console.log(err));
        http.sendRestRequest("home/gettingStarted")
            .then((response) => {
                setGettingStarted(response);
                setShowWelcomeModal(!response.isWorkspaceCreated);
            })
            .catch((err) => console.log(err));
    }, []);
    useEffect(() => {
        getAlertsForHome();
    }, [alertDataList]);
    const closeModal = () => {
        setAlertInfoModal(false);
    };
    const handleAlertInfoButtonList = (index) => {
        switch (index) {
            case 1:
                // this.alertType = "notification";
                setAlertTypeData("notification");
                break;
            case 2:
                // this.alertType = "hidden";
                setAlertTypeData("hidden");

                break;
            case 3:
                // this.alertType = "communicator";
                setAlertTypeData("communicator");
                break;
            case 4:
                // this.alertType = "alert";
                setAlertTypeData("alert");
                break;
            default:
                break;
        }
    };
    const renderEditAlertInfoModal = () => {
        return (
            <EditAlertModalView
                showModal={alertInfoModal}
                // setText={setText}
                onIconClick={handleAlertInfoButtonList}
                onSubmit={updateExistingAlert}
                onHide={closeModal}
                alertBodyRawData={selectedAlertData}
            ></EditAlertModalView>
        );
    };
    const jsonData = [
        {
            title: "Search Knowledge Center",
            content:
                "Search Knowledge Center for answers to frequently asked questions",
            src: "https://boardwalktech.atlassian.net/wiki/spaces/UW/overview",
        },
        {
            title: "Unifying information, data, and work into Unity Central",
            content:
                "How Does It Work? The Cuboid was patented roughly 15 years ago...",
            src: "https://bwt.servicedesk.boardwalktech.com",
        },
        {
            title: "Unifying information, data, and work into Unity Central",
            content:
                "How Does It Work? The Cuboid was patented roughly 15 years ago...",
            src: "https://www.boardwalktech.com/contact-us",
        },
    ];
    const updateAlertManager = () => {
        let promise = new Promise((resolve, reject) => {
            let alertManager = null;
            alertManager = new AlertManager();
            alertManager
                .loadAlertConfig()
                .then((response) => {
                    alertManager.setAlertManagerData(response);
                    let alertArray = [];
                    let existingAlerts = alertManager.loadAllAlerts();
                    let alertData = alertManager.getAllAlerts();
                    // this.setState({ alertData: this.alertData });
                    setAlertDataList(alertData);
                    if (
                        existingAlerts.length !== 0 ||
                        existingAlerts.length !== null
                    ) {
                        existingAlerts.forEach((item) => {
                            alertArray.push(item);
                        });
                        dispatch(setAlertsWidget(alertArray));
                        // this.setState({ showLoader: false });
                    }
                    resolve(alertData);
                })
                .catch((errResp) => {
                    console.log(errResp);
                    // this.setState({ showLoader: false });
                    reject(errResp);
                });
            dispatch(setAlertManager(alertManager));
        });
        return promise;
    };
    const updateExistingAlert = (rawData) => {
        closeModal();
        let id = selectedAlertId;
        let alertTitle = rawData.alertTitle.trim();
        let alertBody = rawData.alertBody.trim();
        let alertType = rawData.alertType.trim();

        if (id > 0 && id !== 1000000) {
            let obj = {
                alertRegistryId: id,
                alertName: alertTitle,
                alertBody: alertBody,
                alertType: alertType,
            };

            alertmanagerGlobal
                .updateAlertRowByAPI(obj)
                .then((updatedObject) => {
                    updateAlertManager()
                        .then((alertData) => {
                            dispatch(setAlertData(alertData));
                            // this.setState({
                            //     alertData: alertData,
                            //     showLoader: false,
                            //     alertInfoModal: false,
                            // });
                            setAlertDataList(alertData);
                            setAlertInfoModal(false);
                        })
                        .catch((err) => {
                            console.log(err.error);
                            // this.showAlertView("", err.error);
                            // this.setState({
                            //     showLoader: false,
                            //     alertInfoModal: false,
                            // });
                            setAlertInfoModal(false);
                        });
                })
                .catch((err) => {
                    console.log(err.error);
                    // this.showAlertView("", err.error);
                    // this.setState({ showLoader: false });
                });
        }
    };
    const dismissGettingStarted = () => {
        http.sendRestRequest("home/gettingStarted/dismiss", "PUT")
            .then(() => console.log("dismissed"))
            .catch(() => console.log("dismiss error"));
        setGettingStarted({
            ...gettingStarted,
            showGettingStarted: false,
        });
    };
    const createWorkspaceHandler = () => {
        document.getElementById("div_leftPane_workspaceDropdown").onclick();
        console.log(document.querySelector("#div_leftPane_workspaceDropdown"));
        dispatch(setAddWorkspaceShowModal(true));
    };
    return (
        <div className="home-container invisibleScroller">
            <div className="home-top">
                {gettingStarted && gettingStarted.showGettingStarted ? (
                    <div className="getting-started">
                        <GettingStarted
                            data={gettingStarted}
                            dismissGettingStarted={dismissGettingStarted}
                        ></GettingStarted>
                    </div>
                ) : null}
                <div className="number-data">
                    <div className="number-data-left">
                        <div style={{ width: "50%", marginRight: "1%" }}>
                            <NumberInfo
                                type="Directory Members"
                                value={counts.directoryMember}
                            ></NumberInfo>
                        </div>
                        <div style={{ width: "50%", marginLeft: "1%" }}>
                            <NumberInfo
                                type="Documents"
                                value={counts.document}
                            ></NumberInfo>
                        </div>
                    </div>
                    <div className="number-data-right">
                        <div style={{ width: "50%", marginRight: "1%" }}>
                            <NumberInfo
                                type="Workspaces"
                                value={counts.workspace}
                            ></NumberInfo>
                        </div>
                        <div style={{ width: "50%", marginLeft: "1%" }}>
                            <NumberInfo
                                type="Flow Instances"
                                value={counts.flowInstance}
                            ></NumberInfo>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-bottom">
                <div className="home-row">
                    <div className="home-column">
                        <div className="box">
                            <div
                                className="alertViewerContainer invisibleScroller"
                                style={{ height: "290px" }}
                            >
                                {allAlerts && (
                                    <Alerts
                                        allAlerts={allAlerts}
                                        setAllAlerts={setAllAlerts}
                                    ></Alerts>
                                )}
                                {allAlerts.length === 0 ? (
                                    <EmptyHomeScreen
                                        heading="Alerts"
                                        image="./images/alert-home.svg"
                                        subTitle="No alerts yet!"
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="home-column">
                        <div className="box">
                            <RecentActivity />
                        </div>
                    </div>
                </div>
                <div className="home-row">
                    <div className="home-column">
                        <div className="box">
                            <DataComponent data={jsonData} />
                        </div>
                    </div>
                    <div className="home-column">
                        <div className="box">
                            <NewChat />
                        </div>
                    </div>
                </div>
            </div>
            {renderEditAlertInfoModal()}
            {showWelcomeModal && (
                <WelcomeShow
                    showModal={showWelcomeModal}
                    onHide={() => {
                        setShowWelcomeModal(!showWelcomeModal);
                        // createWorkspaceHandler();
                    }}
                    onClose={() => {
                        setShowWelcomeModal(!showWelcomeModal);
                        // createWorkspaceHandler();
                    }}
                    openCreateWorkspaceModal={createWorkspaceHandler}
                />
            )}
        </div>
    );
};

export default Home;
